/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
import { useState, useEffect } from "react";
import Select from "./select";

function SelectYear({ setYear, year, data }) {
  const [years, setYears] = useState([]);
  function getDropList() {
    if (data) {
      setYears(data);
    } else {
      const yearArr = [];
      const newYear = new Date("01 January 0 00:00:00 UTC").getFullYear();
      // eslint-disable-next-line no-unused-vars
      Array.from(new Array(30), (v, i) => {
        yearArr.push({
          id: i,
          year: newYear + i,
        });
      });
      setYears(yearArr);
    }
  }
  useEffect(() => {
    getDropList();
  }, [data]);
  return (
    <Select
      options={years}
      selected={year}
      setSelected={setYear}
      title="year"
      index="year"
    />
  );
}

export default SelectYear;
