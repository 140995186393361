/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/prefer-default-export */
import Home from "../components/illustrations/home";
import User from "../components/illustrations/user";
import Scroll from "../components/illustrations/scroll";
import Cooperative from "../components/illustrations/cooperate";
import Deposit from "../components/illustrations/deposit";
import Divident from "../components/illustrations/divident";
import Variations from "../components/illustrations/variation";
import Feedback from "../components/illustrations/feedback";
import Desk from "../components/illustrations/desk";
import Switch from "../components/illustrations/switch";
import Support from "../components/illustrations/support";

const links = [
  {
    id: 1,
    title: "Home",
    link: "/",
    icon: <Home />,
  },
  {
    id: 2,
    title: "Profile",
    link: "/profile",
    icon: <User />,
  },
  {
    id: 3,
    title: "Account Statement",
    link: "/account-summary",
    icon: <Scroll />,
    child: [
      {
        title: "Summary",
        link: "/account-summary",
      },
      {
        title: "Transaction",
        link: "/transactions",
      },
      {
        title: "Payroll",
        link: "/payroll",
      },
    ],
  },
  {
    id: 4,
    title: "Cooperative Loan",
    link: "/loan-application",
    icon: <Cooperative />,
    child: [
      {
        title: "Loan Application",
        link: "/loan-application",
      },
      {
        title: "Gauranteed",
        link: "/guaranteed-loans",
      },
    ],
  },
  {
    id: 5,
    title: "Deposit",
    link: "/bank-deposit",
    icon: <Deposit />,
    child: [
      {
        title: "Bank Deposit",
        link: "/bank-deposit",
      },
      {
        title: "Deposit Withdrawal",
        link: "/deposit-withdrawal",
      },
    ],
  },
  {
    id: 6,
    title: "Dividend Warrant",
    link: "/dividend-warrant",
    icon: <Divident />,
  },
  {
    id: 7,
    title: "Variations",
    link: "/variations",
    icon: <Variations />,
  },
  {
    id: 8,
    title: "Feedback / Complains",
    link: "/feedback",
    icon: <Feedback />,
  },
  {
    id: 9,
    title: "Coop Desk",
    link: "/coop-desk",
    icon: <Desk />,
  },
  {
    id: 10,
    title: "Switch Account",
    link: "/switch-account",
    icon: <Switch />,
  },
  {
    id: 11,
    title: "Help / Support",
    link: "/help",
    icon: <Support />,
  },
];

export default links;
