/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */

export default function Feedback({ className, style }) {
  return (
    <svg className={className} width="22px" height="22px" viewBox="0 0 512 512">
      <path d="m96.898 272.354h28.596v30h-28.596z" />
      <path d="m186.771 272.354h28.724v30h-28.724z" />
      <path d="m156.196 383.163c-19.041 0-34.532-15.491-34.532-34.532h-30c0 35.583 28.949 64.532 64.532 64.532s64.532-28.949 64.532-64.532h-30c0 19.041-15.491 34.532-34.532 34.532z" />
      <path d="m426.506 44.918c-21.706-11.114-46.15-16.988-70.69-16.988-84.511 0-153.469 67.848-155.277 151.934-14.286-4.26-29.121-6.424-44.343-6.424-85.645 0-156.196 69.676-156.196 155.32 0 85.638 70.551 155.31 156.195 155.31 83.324 0 153.521-68.601 155.282-151.927 47.817 14.245 101.03 4.143 140.223-26.733 36.979-29.132 60.3-74.901 60.3-122.16 0-57.526-34.47-112.207-85.494-138.332zm-169.899 358.833c-23.937 31.979-60.536 50.319-100.412 50.319-69.102 0-126.32-56.214-126.32-125.31 0-69.102 57.218-125.32 126.32-125.32 68.126 0 125.32 57.182 125.32 125.32 0 27.286-8.614 53.218-24.908 74.991zm199.608-145.507c-33.746 45.099-95.132 62.451-147.477 41.188-3.139-16.376-8.973-32.25-17.143-46.783h29.683c0-19.041 15.491-34.532 34.532-34.532s34.532 15.491 34.532 34.532h30c0-35.583-28.949-64.532-64.532-64.532-35.401 0-64.225 28.657-64.518 63.991-14.151-24.947-35.327-45.772-60.459-59.583-5.221-71.59 53.141-134.595 124.982-134.595 67.606 0 126.31 57.138 126.31 125.32 0 27.298-9.612 53.229-25.91 74.994z" />
      <path d="m296.513 126.841h28.596v30h-28.596z" />
      <path d="m386.385 126.841h28.724v30h-28.724z" />
    </svg>
  );
}
