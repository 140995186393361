/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */

export default function Lock({ className, style }) {
  return (
    // <svg xmlns="http://www.w3.org/2000/svg" width="17" height="19" viewBox="0 0 17 19"><defs><style>.a{fill:none;stroke:#fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:2px;}</style></defs>
    // </svg>
    <svg
      style={{
        fill: "none",
        stroke: "#fff",
        strokeLinecap: "round",
        strokeLinejoin: "round",
        strokeWidth: "2px",
      }}
      className={className}
      width="14px"
      height="14px"
      viewBox="0 0 17 19"
    >
      <g transform="translate(-3.5 -2)">
        <path
          className="a"
          d="M6.167,16.5H17.833A1.992,1.992,0,0,1,19.5,18.7v7.713a1.992,1.992,0,0,1-1.667,2.2H6.167a1.992,1.992,0,0,1-1.667-2.2V18.7a1.992,1.992,0,0,1,1.667-2.2Z"
          transform="translate(0 -8.62)"
        />
        <path
          className="a"
          d="M10.5,7.879V5.711A2.921,2.921,0,0,1,13.593,3a2.921,2.921,0,0,1,3.093,2.711V7.879"
          transform="translate(-1.593)"
        />
      </g>
    </svg>
  );
}
