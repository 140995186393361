/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */

export default function Variation({ className, style }) {
  return (
    <svg className={className} width="18px" height="18px" viewBox="0 0 64 64">
      <path d="m60 29h-9v-15h1.465c1.397 0 2.535-1.138 2.535-2.535 0-.502-.147-.988-.426-1.406l-5.188-7.781c-.533-.8-1.425-1.278-2.386-1.278s-1.853.478-2.387 1.277l-5.188 7.781c-.278.419-.425.905-.425 1.407 0 1.397 1.138 2.535 2.535 2.535h1.465v15h-9c-.352 0-.686.072-1 .184v-25.184c0-1.654-1.346-3-3-3h-26c-1.654 0-3 1.346-3 3v28c0 1.654 1.346 3 3 3h9v15h-1.465c-1.397 0-2.535 1.138-2.535 2.535 0 .502.147.988.426 1.406l5.188 7.781c.533.8 1.425 1.278 2.386 1.278s1.853-.478 2.387-1.277l5.188-7.781c.278-.419.425-.905.425-1.407 0-1.397-1.138-2.535-2.535-2.535h-1.465v-15h9c.352 0 .686-.072 1-.184v25.184c0 1.654 1.346 3 3 3h26c1.654 0 3-1.346 3-3v-28c0-1.654-1.346-3-3-3zm-18.465-17c-.295 0-.535-.24-.535-.535 0-.106.031-.209.09-.297l5.188-7.781c.322-.484 1.123-.484 1.445 0l5.188 7.781c.059.088.09.19.09.297-.001.295-.241.535-.536.535h-3.465v17h-4v-17zm-7.535 19h26c.552 0 1 .448 1 1v5h-28v-5c0-.552.448-1 1-1zm-30-28h26c.552 0 1 .448 1 1v5h-28v-5c0-.552.448-1 1-1zm18.465 49c.295 0 .535.24.535.535 0 .106-.031.209-.09.297l-5.188 7.781c-.322.484-1.123.484-1.445 0l-5.188-7.781c-.059-.088-.09-.19-.09-.297.001-.295.241-.535.536-.535h3.465v-17h4v17zm7.535-19h-26c-.552 0-1-.448-1-1v-21h28v21c0 .552-.448 1-1 1zm30 28h-26c-.552 0-1-.448-1-1v-21h28v21c0 .552-.448 1-1 1z" />
      <path d="m35 33h2v2h-2z" />
      <path d="m39 33h2v2h-2z" />
      <path d="m43 33h2v2h-2z" />
      <path d="m13 5h2v2h-2z" />
      <path d="m5 5h2v2h-2z" />
      <path d="m9 5h2v2h-2z" />
      <path d="m49.323 49.225c.427-.637.677-1.402.677-2.225 0-2.206-1.794-4-4-4h-4v14h6c2.206 0 4-1.794 4-4 0-1.742-1.12-3.228-2.677-3.775zm-5.323-4.225h2c1.103 0 2 .897 2 2s-.897 2-2 2h-2zm4 10h-4v-4h4c1.103 0 2 .897 2 2s-.897 2-2 2z" />
      <path d="m17 15c-3.309 0-6 2.691-6 6v8h2v-4h8v4h2v-8c0-3.309-2.691-6-6-6zm-4 8v-2c0-2.206 1.794-4 4-4s4 1.794 4 4v2z" />
    </svg>
  );
}
