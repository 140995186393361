/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */

export default function Desk({ className, style }) {
  return (
    <svg className={className} width="18px" height="18px" viewBox="0 0 512 512">
      <path d="m51.433 286.365c-5.11 0-10.076-.621-14.842-1.76v212.538c0 8.205 6.652 14.857 14.857 14.857h409.105c8.205 0 14.857-6.652 14.857-14.857v-212.538c-4.766 1.139-9.732 1.76-14.842 1.76z" />
      <path d="m162.585 158.682v-35.537h57.07c8.205 0 14.857-6.652 14.857-14.857v-93.431c-.001-8.205-6.652-14.857-14.857-14.857h-143.854c-8.205 0-14.857 6.652-14.857 14.857v93.432c0 8.205 6.652 14.857 14.857 14.857h57.07v35.537h29.714z" />
      <path d="m427.134 158.682v-49.372c0-8.205-6.652-14.857-14.857-14.857s-14.857 6.652-14.857 14.857v49.372z" />
      <path d="m460.567 188.395h-409.134c-18.849 0-34.128 15.28-34.128 34.128 0 18.849 15.28 34.129 34.128 34.129h409.134c18.849 0 34.128-15.28 34.128-34.129 0-18.848-15.279-34.128-34.128-34.128z" />
    </svg>
  );
}
