/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable no-undef */
import React from "react";
import { Link } from "react-router-dom";
import { DefaultNavBottom, DefaultNavLists, NavLink } from "./navbar.style";
import logo from "../../assests/roundedLogo.svg";
import logout from "../../assests/logout.svg";
import Lock from "../illustrations/lock";

const style = {
  color: "white",
  fontWeight: "bold",
  fontSize: "13px",
};

const navStyle = {
  marginTop: "20px",
};
const spanStyle = {
  marginLeft: "5px",
};
function defaultNavItem() {
  const onLogout = () => {
    localStorage.sekatAuthStatus = false;
    window.location.href = "/signin";
  };

  return (
    <DefaultNavLists>
      <Link to="/">
        {" "}
        <img src={logo} alt="logo" />
      </Link>

      <DefaultNavBottom>
        <Link to="/?changePin=true" style={style}>
          <NavLink style={navStyle}>
            {" "}
            <Lock /> <span style={spanStyle}>Change pin</span>
          </NavLink>
        </Link>
        <Link style={style}>
          <NavLink style={navStyle} onClick={() => onLogout()}>
            {" "}
            <img src={logout} alt="logout-icon" />{" "}
            <span style={spanStyle}>Logout</span>
          </NavLink>
        </Link>
      </DefaultNavBottom>
    </DefaultNavLists>
  );
}

export default defaultNavItem;
