/* eslint-disable import/no-extraneous-dependencies */
/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const LayoutWrapper = styled.div`
  display: flex;
  width: 100%;
  @media (max-width: 992px) {
    flex-direction: column;
  }
`;
