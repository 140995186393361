/* eslint-disable import/prefer-default-export */
import styled from "styled-components";

export const ErrorWrapper = styled.div`
  margin: 0px auto;
  margin-top: 1rem;
  max-width: 64.375rem;
  box-shadow: 0px 3px 12px #00000024;
  min-height: 90vh;
  border-radius: 10px;
  padding: 2.313rem;
  position: relative;
  display: flex;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  @media (max-width: 992px) {
    padding: 0px;
    box-shadow: none;
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom: 20px;
  }
`;

export const ErrorContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  & > pre {
    word-break: break-all !important;
  }
`;
