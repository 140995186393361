/* eslint-disable import/prefer-default-export */
import api from "../apis/user";
import {
  FETCHING_COOPERATIVES,
  SUCCESSFULLY_FETCHED_COOPERATIVES,
  FAILED_FECTHING_COOPERATIVES,
} from "../types/cooperatives";
import { SHORT_SIGNUP_STEP1, SHORT_SIGNUP_STEP2 } from "../types/auth";
import {
  FETCHING_USER,
  FETCHING_USER_ERROR,
  FETCHING_USER_SUCCESSFULL,
} from "../types/user";

export const getCooperatives = () => (dispatch) => {
  console.log("dispatch is ", dispatch);
  dispatch({
    type: FETCHING_COOPERATIVES,
  });
  console.log("dispatch2 is ", dispatch);

  return api
    .getCooperatives()
    .then((res) => {
      dispatch({
        type: SUCCESSFULLY_FETCHED_COOPERATIVES,
        results: res,
      });
    })
    .catch((error) => {
      // eslint-disable-next-line no-console
      console.log("error is: ", error);
      dispatch({
        type: FAILED_FECTHING_COOPERATIVES,
        error,
      });
    });
};

export const sendVerificationCode = (phone, email, code) => () => {
  return api.sendVerificationCode(phone, email, code);
};

export const verifyCode = (phone, code) => () => {
  return api.verifyCode(phone, code);
};

export const linkUpWeb = (phone, coop, code) => () => {
  return api.linkUpWeb(phone, coop, code);
};

export const shortStepOne = (data) => (dispatch) => {
  dispatch({
    type: SHORT_SIGNUP_STEP1,
    data,
  });
};

export const shortStepTwo = (data) => (dispatch) => {
  dispatch({
    type: SHORT_SIGNUP_STEP2,
    data,
  });
};

export const shortSignup = (data, coop) => () => {
  return api.shortSignup(data, coop);
};
export const getSignupType = (coop) => () => {
  return api.getSignUpstat(coop);
};
export const login = (pin, token, phone, coop) => () => {
  return api.login(pin, token, phone, coop);
};
export const validateToken = (coop, token, phone) => () => {
  return api.validateToken(coop, token, phone);
};
export const getUser = (token, coop) => (dispatch) => {
  dispatch({
    type: FETCHING_USER,
  });
  return api
    .getUser(token, coop)
    .then((res) => {
      if (!res.object) {
        dispatch({
          type: FETCHING_USER_ERROR,
          error: res,
        });
      } else {
        dispatch({
          type: FETCHING_USER_SUCCESSFULL,
          result: res,
        });
      }
    })
    .catch((error) => {
      dispatch({
        type: FETCHING_USER_ERROR,
        error,
      });
    });
};

export const getTitle = (coop) => () => {
  return api.getTitle(coop);
};

export const forgotPin = (phone, coop) => () => {
  return api.forgotPin(phone, coop);
};

export const getList = (coop) => () => {
  return api.getList(coop);
};

export const createPin = (coop, phone, pin, token) => () => {
  return api.createPin(coop, phone, pin, token);
};

export const resetPin = (coop, phone, oldPin, newPin) => () => {
  return api.resetPin(coop, phone, oldPin, newPin);
};

export const getSignupStatus = (coop) => () => {
  return api.getSignupStatus(coop);
};

export const getBasicInfo = (coop, coopId) => () => {
  return api.getBasicInfo(coop, coopId);
};
export const getFinancialInfo = (coop, coopId) => () => {
  return api.getFinancialInfo(coop, coopId);
};
export const getBeneficiaryInfo = (coop, coopId) => () => {
  return api.getBeneficiaryInfo(coop, coopId);
};
export const updateBasicInfo = (coop, details) => () => {
  return api.updateBasicInfo(coop, details);
};
export const updateFinancialInfo = (coop, details) => () => {
  return api.updateFinancialInfo(coop, details);
};
export const updateBeneficiaryInfo = (coop, data) => () => {
  return api.updateBeneficiaryInfo(coop, data);
};

export const getCoopDesk = (coop) => () => {
  return api.getCoopDesk(coop);
};

export const sendComplain = (coop, coopId, subject, message) => () => {
  return api.sendComplain(coop, coopId, subject, message);
};

export const getComplaintHistory = (coop, coopId) => () => {
  return api.getComplaintHistory(coop, coopId);
};
