/* eslint-disable import/prefer-default-export */
import styled from "styled-components";
import { animated } from "react-spring";
import colors from "../theme/colors.json";

export const ModalWrapper = styled(animated.div)`
  position: fixed;
  display: ${(props) => (props.show === true ? "block" : "none")};
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  z-index: 999;
  height: 100%;
  overflow: auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
`;

export const ModalSpace = styled.div`
  width: ${(props) => (props.spacing ? props.spacing : "319px")};
  @media (max-width: 992px) {
    display: none;
  }
`;

export const ModalContainer = styled(animated.div)`
  margin: 0px auto;
  border-radius: 10px;
  padding: 10px;
  width: 90%;
  min-height: 20vh;
  max-height: 80vh;
  @media (min-width: 992px) {
    width: auto;
    max-height: 80vh;
    padding: 40px;
    width: ${(props) => (props.size === "large" ? "60%" : "30%")};
  }
  background: white;
  & > div:nth-child(2) {
    max-height: 70vh;
    overflow-y: auto;
    overflow-x: auto;
    padding-bottom: 10px;
    & > div:nth-child(1) {
      min-height: 20vh;
    }
  }
`;

export const ModalHead = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px;
  border-radius: 5px;
  background: ${(props) => props.style && colors.primary};
  color: ${(props) => props.style && colors.secondary};
  @media (max-width: 992px) {
    padding-top: 10px;
  }
  & > button {
    background: transparent;
    border: none;
    cursor: pointer;
  }
`;

export const ModalTitle = styled.div`
  font-size: 16px;
  font-weight: bold;
`;
