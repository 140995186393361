/* eslint-disable react/prop-types */
import React from "react";

export default function Menu({ className, onClick, color, style }) {
  return (
    <svg
      onClick={onClick}
      style={style}
      className={className}
      width="30"
      height="30"
      viewBox="0 0 35 35"
    >
      <path
        d="M4.375 26.25H30.625V23.3333H4.375V26.25ZM4.375 18.9583H30.625V16.0417H4.375V18.9583ZM4.375 8.75V11.6667H30.625V8.75H4.375Z"
        fill={color}
      />
    </svg>
  );
}
