/* eslint-disable import/no-extraneous-dependencies */
import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import DownArrow from "../illustrations/downArrow";
import ModalImage from "react-modal-image";
import profile from "./profile.png";
import {
  ProfileWrapper,
  NavLists,
  NavList,
  ArrowDown,
  NavLink,
  NavIcon,
  SubNav,
  NavIndicator,
  NavListWrapper,
  Avatar,
} from "./navbar.style";
import avatar from "../../assets/default.svg";

const truncateStyle = {
  whiteSpace: "nowrap",
  overflow: "hidden",
  fontWeight: "bold",
  width: "100px",
  textOverflow: "ellipsis",
  textAlign: "center",
};

function SideNav({ history, user, links, setShow }) {
  const { state } = history.location;
  const [active, setActive] = useState("");
  const [activeSubnav, setActiveSubnav] = useState(null);
  const [adminMode, setAdminMode] = useState(false);
  const { pathname } = history.location;
  useEffect(() => {
    if (pathname) {
      setActive(pathname);
    }
  }, [pathname]);
  const userInfo = user.object;
  const imgg = userInfo.cm_pic ? userInfo.cm_pic : profile;
  return (
    <div>
      <ProfileWrapper>
        <ModalImage
          large={imgg}
          alt="view image"
          small={imgg}
          className="modalavatar"
          hideZoom
          hideDownload
        />

        <div>
          {" "}
          <p style={truncateStyle}>
            {userInfo.surname} {userInfo.otherName}
          </p>
        </div>
        <div>
          <p>{userInfo.coopMemNo}</p>
        </div>
        <div>
          <p style={truncateStyle}>{userInfo.cooperativeName}</p>
        </div>
      </ProfileWrapper>
      <NavLists>
        {links &&
          Object.keys(links).length > 0 &&
          links.map((link) => {
            let isActive = false;
            if (active.includes(link.link) && link.link !== "/") {
              isActive = true;
            }
            if (link.child) {
              const { child } = link;
              // eslint-disable-next-line no-plusplus
              for (let i = 0; i < child.length; i++) {
                if (child[i].link === active) {
                  isActive = true;
                  break;
                }
              }
            }
            return (
              <div key={link.link}>
                <Link
                  to={{
                    pathname: link.child ? pathname : link.link,
                    state: {
                      ...state,
                      id: link.id,
                    },
                  }}
                  onClick={() => {
                    setActive(link.link);
                    if (!link.child) {
                      setShow(false);
                    }
                  }}
                >
                  <NavList active={isActive}>
                    <NavIndicator active={isActive} />
                    <NavListWrapper>
                      <NavLink>
                        <NavIcon active={isActive}>{link.icon}</NavIcon>
                        <span>{link.title}</span>
                      </NavLink>
                      {link.child && Object.keys(link.child).length ? (
                        <ArrowDown>
                          <NavIcon active={isActive}>
                            <DownArrow />
                          </NavIcon>
                        </ArrowDown>
                      ) : (
                        <span></span>
                      )}
                    </NavListWrapper>
                  </NavList>
                </Link>
                {link.child &&
                Object.keys(link.child).length &&
                state &&
                link.id === state.id ? (
                  <SubNav>
                    {link.child.map((i) => {
                      let isActiveSub = false;
                      if (activeSubnav === i.link) {
                        isActiveSub = true;
                      }
                      return (
                        <Link
                          key={i.link}
                          to={i.link}
                          onClick={() => {
                            setActiveSubnav(i.link);
                            setShow(false);
                          }}
                        >
                          <NavList active={isActiveSub}>{i.title}</NavList>
                        </Link>
                      );
                    })}
                  </SubNav>
                ) : (
                  <div></div>
                )}
              </div>
            );
          })}
      </NavLists>
    </div>
  );
}

SideNav.propTypes = {
  history: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default SideNav;
