/* eslint-disable react/no-unescaped-entities */
import { Button, ButtonWrapper, colors } from "@micmac/components";
import { useHistory } from "react-router-dom";
import React from "react";
import { ErrorContent, ErrorWrapper } from "./error.style";

function Error() {
  const history = useHistory();
  return (
    <ErrorWrapper>
      <ErrorContent>
        <h1>Ooops! Sorry, This page failed to load</h1>
        <p>It's seems this page does'nt exist</p>
        <ButtonWrapper>
          <Button
            onClick={() => history.push("/signin")}
            theme={{
              wd: "200px",
              bg: colors.primary,
              fg: colors.secondary,
              pd: "10px",
            }}
          >
            Go back
          </Button>
        </ButtonWrapper>
      </ErrorContent>
    </ErrorWrapper>
  );
}

export default Error;
