/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import { useSpring } from "react-spring";
import PropTypes from "prop-types";
import {
  ModalContainer,
  ModalHead,
  ModalSpace,
  ModalTitle,
  ModalWrapper,
} from "./modal.style";
import Cancel from "../illustrations/cancel";

function Modal({ title, onClose, show, content, size, styleHead, spacing }) {
  const modalAnimation = useSpring({
    from: {
      opacity: "0px",
    },
    to: {
      opacity: "1",
    },
  });
  return (
    <ModalWrapper show={show}>
      <ModalSpace spacing={spacing} />
      <ModalContainer size={size} style={modalAnimation}>
        <ModalHead style={styleHead}>
          <ModalTitle>{title}</ModalTitle>
          <button onClick={() => onClose(false)}>
            <Cancel />
          </button>
        </ModalHead>
        {content}
      </ModalContainer>
    </ModalWrapper>
  );
}

Modal.propTypes = {
  title: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  show: PropTypes.bool.isRequired,
  content: PropTypes.element.isRequired,
  size: PropTypes.string,
  styleHead: PropTypes.bool,
};

export default Modal;
