/* eslint-disable import/prefer-default-export */
import styled from "styled-components";
import colors from "./colors.json";

export const Button = styled.button`
  border-radius: 10px;
  min-width: ${(props) => props.theme.wd || "100%"};
  padding: ${(props) => props.theme.pd || "3px"};
  background: ${(props) => props.theme.bg};
  border: 1px solid ${(props) => props.theme.fg};
  color: ${(props) => props.theme.fg};
  cursor: pointer;
  line-height: 2.125rem;
  &:disabled {
    cursor: not-allowed;
    background: ${colors.gray};
  }
  font-family: "Lato", sans-serif;
  & > div {
    display: flex;
    align-items: center;
    justify-content: center;
    & > span {
      padding-left: 15px;
    }
  }
`;

export const ButtonWrapper = styled.div`
  display: flex;
  justify-content: center;
  padding-top: 30px;
`;

export const Select = styled.details`
  position: relative;
  width: 100%;
  &[open] {
    z-index: 1;
  }
  &[open] > summary:after {
    transform: rotate(-45deg) translate(0%, 0%);
  }
`;

export const SelectedOptionWrapper = styled.summary`
  font-size: 0.875rem;
  line-height: 1.125rem;
  padding: 0.4rem 0.9375rem;
  border-radius: 4px;
  border: 1px solid ${colors.primary};
  cursor: pointer;
  border-radius: 4x;
  list-style: none;
  display: flex;
  justify-content: space-between;
  &::-webkit-details-marker {
    display: none;
  }
  [open] &:before {
    content: "";
    display: block;
    width: 100vw;
    height: 100vh;
    background: transparent;
    position: fixed;
    top: 0;
    left: 0;
  }
  &:after {
    content: "";
    display: inline-block;
    float: right;
    width: 0.5rem;
    height: 0.5rem;
    border-bottom: 1px solid currentColor;
    border-left: 1px solid currentColor;
    border-bottom-left-radius: 2px;
    transform: rotate(45deg) translate(50%, 0%);
    transform-origin: center center;
    transition: transform ease-in-out 100ms;
  }
  &:focus {
    outline: none;
  }
  & > p {
    padding: 0px;
    margin: 0px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 300px;
  }
  & > span {
    color: ${colors.gray};
  }
`;

export const SelectLists = styled.ul`
  list-style: none;
  margin: 0px;
  padding: 0px;
  width: 100%;
  background: white;
  box-shadow: 0px 10px 20px rgb(0 0 0 / 5%);
  position: absolute;
  top: calc(100% + 0.5rem);
  left: 0;
  margin: 0;
  box-sizing: border-box;
  border-radius: 5px;
  max-height: 400px;
  overflow-y: auto;
`;

export const SelectList = styled.li`
  cursor: pointer;
  margin: 0;
  padding: 10px 10px 10px 10px;
  font-size: 15px;
  background: ${(props) => (props.active ? colors.primary : "")};
  &:hover {
    background: ${colors.primary};
    transition: ease-in-out 1s;
  }
  &:first-child {
    padding-top: 0;
  }
  &:last-child {
    padding-bottom: 10px;
    border-bottom: none;
  }
`;
