/* eslint-disable array-callback-return */
/* eslint-disable react/prop-types */
import Select from "./select";

function SelectMonths({ setMonth, month }) {
  const months = [
    {
      id: 1,
      month: "January",
    },
    {
      id: 2,
      month: "February",
    },
    {
      id: 3,
      month: "March",
    },
    {
      id: 4,
      month: "April",
    },
    {
      id: 5,
      month: "May",
    },
    {
      id: 6,
      month: "June",
    },
    {
      id: 7,
      month: "July",
    },
    {
      id: 8,
      month: "August",
    },
    {
      id: 9,
      month: "September",
    },
    {
      id: 10,
      month: "October",
    },
    {
      id: 11,
      month: "November",
    },
    {
      id: 12,
      month: "December",
    },
  ];
  return (
    <Select
      options={months}
      selected={month}
      setSelected={setMonth}
      title="Month"
      index="month"
    />
  );
}

export default SelectMonths;
