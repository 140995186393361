/* eslint-disable react/prop-types */
import { React, useState, useEffect } from "react";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { Link, withRouter } from "react-router-dom";
import { useMediaQuery } from "react-responsive";
import Plusicon from "../../assests/uparrow.png";

import {
  WidgetContainer,
  WidgetWrapper,
  Applist,
  AppName,
  TradeMarkWrapper,
  Toggle,
} from "./widgets.style";
import allData from "../../data/allApps";

const responsive = {
  superLargeDesktop: {
    breakpoint: { max: 4000, min: 3000 },
    items: 8,
    partialVisibilityGutter: 15,
  },
  desktop: {
    breakpoint: { max: 3000, min: 1024 },
    items: 5,
    partialVisibilityGutter: 15,
  },
  mobile: {
    breakpoint: { max: 464, min: 0 },
    items: 3,
    partialVisibilityGutter: 20,
  },
};

const setApp = (name) => {
  localStorage.sekatApp = name;
};

function AppToggler({ history }) {
  const { pathname } = history.location;
  const isMobile = useMediaQuery({ maxWidth: "992px" });
  const [show, setShow] = useState(false);
  useEffect(() => {
    // eslint-disable-next-line
    console.log(show);
  }, [show]);
  return (
    <div
      style={{
        position: "sticky",
        bottom: "0px",
        width: "100%",
      }}
    >
      {pathname !== "/" && (
        <WidgetContainer>
          <Toggle
            onClick={() => {
              if (show) {
                setShow(false);
              } else {
                setShow(true);
              }
            }}
          >
            <img
              src={Plusicon}
              alt="plus-icon"
              style={{
                position: "absolute !important",
                height: "35px",
                transform: show && "rotate(180deg)",
              }}
            />
          </Toggle>
          {show && (
            <WidgetWrapper>
              <Carousel
                draggable
                arrows={!isMobile && true}
                className="apps-slider"
                partialVisbile={true}
                responsive={responsive}
              >
                {allData.map((app) => {
                  return (
                    <Applist
                      onClick={() => setApp(app.localName)}
                      bg={app.bg}
                      key={app.name}
                      img={app.icon}
                    >
                      <Link to={app.link}>
                        <div>
                          <div>
                            <img src={app.icon} alt={app.icon} />
                          </div>
                        </div>
                        <AppName
                          active={
                            localStorage.sekatApp === app.localName && true
                          }
                        >
                          {app.name}
                        </AppName>
                      </Link>
                    </Applist>
                  );
                })}
              </Carousel>
            </WidgetWrapper>
          )}
        </WidgetContainer>
      )}
      <TradeMarkWrapper>
        Micmac™ is a registered trademark of Sekat Group. ©
        <span>{new Date().getFullYear()}</span>
      </TradeMarkWrapper>
    </div>
  );
}

export default withRouter(AppToggler);
