/* eslint-disable import/no-extraneous-dependencies */
import axios from "axios";

const endpoint = process.env.REACT_APP_API_ENDPOINT;
const secret = process.env.REACT_APP_SECRET;
export default {
  getCooperatives: () =>
    axios
      .get(`${endpoint}/api/User/getcooperatives`, {
        headers: {
          ClientSecrete: secret,
        },
      })
      .then((res) => {
        // eslint-disable-next-line no-console
        console.log(res.data);
        return res.data;
      }),
  getNotification: () =>
    axios
      .get(`${endpoint}/api/CoopUser/GetUserNotification`, {
        headers: {
          ClientSecrete: secret,
        },
      })
      .then((res) => {
        return res.data;
      }),
  getSignUpstat: (coop) =>
    axios
      .get(`${endpoint}/api/CoopUser/CheckSignUpStatusWeb`, {
        headers: {
          ClientSecrete: coop,
        },
      })
      .then((res) => {
        return res.data;
      }),
  sendVerificationCode: (phone, email, code) => {
    const params = new URLSearchParams();
    params.append("PhoneNumber", phone);
    params.append("Email", email);
    params.append("CountryCode", code);
    return axios
      .post(`${endpoint}/api/CoopUser/SendCode`, params, {
        headers: {
          ClientSecrete: secret,
          "content-type": "application/x-www-form-urlencoded",
        },
      })
      .then((res) => res.data);
  },
  verifyCode: (phone, code) => {
    const params = new URLSearchParams();
    params.append("PhoneNumber", phone);
    params.append("code", code);
    return axios
      .post(`${endpoint}/api/CoopUser/VerifyCode`, params, {
        headers: {
          ClientSecrete: secret,
          "content-type": "application/x-www-form-urlencoded",
        },
      })
      .then((res) => res.data);
  },
  linkUpWeb: (phone, coop, code) => {
    const params = new URLSearchParams();
    params.append("PhoneNumber", phone);
    params.append("CountryCode", code);
    return axios
      .post(`${endpoint}/api/CoopUser/LinkUpAccountWeb`, params, {
        headers: {
          ClientSecrete: coop,
          "content-type": "application/x-www-form-urlencoded",
        },
      })
      .then((res) => res.data);
  },
  login: (pin, token, phone, coop) => {
    const params = new URLSearchParams();
    params.append("Pin", pin);
    params.append("Usertoken", token);
    params.append("Phonenumber", phone);
    return axios
      .post(`${endpoint}/api/CoopUser/ValidateLoginWeb`, params, {
        headers: {
          ClientSecrete: coop,
        },
      })
      .then((res) => res.data);
  },

  shortSignup: (data, coop) => {
    const params = new URLSearchParams();
    params.append("phoneNumber", data.phoneNumber);
    params.append("PIN", data.pin);
    params.append("CountryCode", data.code);
    params.append("email", data.email);
    params.append("surName", data.firstName);
    params.append("othertName", data.lastName);
    params.append("coopName", data.cooperative);
    params.append("TitleID", data.title);
    params.append("SignUpType", data.signUpType);
    params.append("MonthlyContrbute", data.monthlyContribution);
    return axios
      .post(`${endpoint}/api/CoopUser/SignUpShort`, params, {
        headers: {
          ClientSecrete: coop,
        },
      })
      .then((res) => res.data);
  },
  getUser: (token, coop) => {
    return axios
      .get(`${endpoint}/api/CoopUser/GetProfile?userToken=${token}`, {
        headers: {
          ClientSecrete: coop,
        },
      })
      .then((res) => res.data);
  },
  validateToken: (coop, token, phone) => {
    const params = new URLSearchParams();
    params.append("Usertoken", token);
    params.append("Phonenumber", phone);
    return axios
      .post(`${endpoint}/api/CoopUser/ValidateUserTokenWeb`, params, {
        headers: {
          ClientSecrete: coop,
        },
      })
      .then((res) => res.data);
  },
  getTitle: (coop) =>
    axios
      .get(`${endpoint}/api/CoopUser/GetTitle`, {
        headers: {
          ClientSecrete: coop,
        },
      })
      .then((res) => res.data),
  forgotPin: (phone, coop) => {
    const params = new URLSearchParams();
    params.append("Phonenumber", phone);
    return axios
      .post(`${endpoint}/api/CoopUser/ForgetPin`, params, {
        headers: {
          ClientSecrete: coop,
        },
      })
      .then((res) => res.data);
  },
  getList: (coop) => {
    return axios
      .get(`${endpoint}/api/CoopUser/GetList`, {
        headers: {
          ClientSecrete: coop,
        },
      })
      .then((res) => res.data);
  },
  getCoopDesk: (coop) =>
    axios
      .get(`${endpoint}/api/CoopUser/GetFiletUrl`, {
        headers: {
          ClientSecrete: coop,
        },
      })
      .then((res) => res.data),
  createPin: (coop, phone, pin, token) => {
    const params = new URLSearchParams();
    params.append("NewPin", pin);
    params.append("UserToken", token);
    params.append("PhoneNumber", phone);
    return axios
      .post(`${endpoint}/api/CoopUser/CreatePinWeb`, params, {
        headers: {
          ClientSecrete: coop,
        },
      })
      .then((res) => res.data);
  },
  resetPin: (coop, phone, oldPin, newPin) => {
    const params = new URLSearchParams();
    params.append("NewPin", newPin);
    params.append("OldPin", oldPin);
    params.append("PhoneNumber", phone);
    return axios
      .post(`${endpoint}/api/CoopUser/ResetPinWeb`, params, {
        headers: {
          ClientSecrete: coop,
        },
      })
      .then((res) => res.data);
  },
  getSignupStatus: (coop) => {
    return axios
      .get(`${endpoint}/api/CoopUser/CheckSignUpStatusWeb`, {
        headers: {
          ClientSecrete: coop,
        },
      })
      .then((res) => res.data);
  },
  getBasicInfo: (coop, coopId) =>
    axios
      .get(`${endpoint}/api/CoopUser/GetBasicInfo?CoopID=${coopId}`, {
        headers: {
          ClientSecrete: coop,
        },
      })
      .then((res) => res.data),
  updateBasicInfo: (coop, details) => {
    const params = new URLSearchParams();
    params.append("Surname", details.surname.value);
    params.append("CoopMemNo", details.coopID.value);
    params.append("TitleID", details.titleID.id || details.titleID.value);
    params.append("OtherName", details.otherName.value);
    params.append(
      "Location",
      details.locationID.id || details.locationID.value
    );
    params.append("ImagePath", details.imagePath.value);
    params.append(
      "DepartmentID",
      details.departmentID.id || details.departmentID.value
    );
    params.append(
      "PositionID",
      details.positionID.id || details.positionID.value
    );
    params.append("maritalStatus", details.maritalStatus.value);
    params.append(
      "LocationID",
      details.locationID.id || details.locationID.value
    );
    params.append("EmployeeNo", details.employeeNo.value);
    params.append("Sex", details.sex.value);
    params.append("PayunitID", details.payunitID.id || details.payunitID.value);
    params.append("Email", details.email.value);
    params.append(
      "CategoryID",
      details.categoryID.id || details.categoryID.value
    );
    params.append(
      "MembershipStatusID",
      details.membershipStatusID.id || details.membershipStatusID.value
    );
    params.append("PhoneNumber", details.phoneNumber.value);
    params.append("Birthdate", details.birthdate.value);
    return axios
      .post(`${endpoint}/api/CoopUser/UpdateBasicInfo`, params, {
        headers: {
          ClientSecrete: coop,
        },
      })
      .then((res) => res.data);
  },
  getFinancialInfo: (coop, coopId) =>
    axios
      .get(`${endpoint}/api/CoopUser/GetFinancialInfo?CoopID=${coopId}`, {
        headers: {
          ClientSecrete: coop,
        },
      })
      .then((res) => res.data),
  updateFinancialInfo: (coop, details) => {
    const params = new URLSearchParams();
    const phone = localStorage.sekatPhone;
    params.append("PhoneNumber", phone);
    params.append("SABankName", details.saBankName.value);
    params.append("SABankAddress", details.saBankAddress.value);
    params.append("AccountName", details.accountName.value);
    params.append("SavingsAccountNo", details.savingsAccountNo.value);
    params.append("SavingsAmt", details.savingsAmt.value);
    return axios
      .post(`${endpoint}/api/CoopUser/UpdateFinanceInfo`, params, {
        headers: {
          ClientSecrete: coop,
        },
      })
      .then((res) => res.data);
  },
  getBeneficiaryInfo: (coop, coopId) =>
    axios
      .get(`${endpoint}/api/CoopUser/GetBeneficiaryInfo?CoopID=${coopId}`, {
        headers: {
          ClientSecrete: coop,
        },
      })
      .then((res) => res.data),
  updateBeneficiaryInfo: (coop, data) => {
    const params = new URLSearchParams();
    params.append("benefiaryInfosJson", data);
    return axios
      .post(`${endpoint}/api/CoopUser/UpdateBenefiaryInfo`, params, {
        headers: {
          ClientSecrete: coop,
        },
      })
      .then((res) => res.data);
  },
  sendComplain: (coop, coopId, subject, message) => {
    const params = new URLSearchParams();
    params.append("subject", subject);
    params.append("messages", message);
    params.append("coopid", coopId);
    return axios
      .post(`${endpoint}/api/FeedBackComplain/SendFeedBackComplain`, params, {
        headers: {
          ClientSecrete: coop,
        },
      })
      .then((res) => res.data);
  },
  getComplaintHistory: (coop, coopId) =>
    axios
      .get(
        `${endpoint}/api/FeedBackComplain/FeedBackHistory?coopid=${coopId}`,
        {
          headers: {
            ClientSecrete: coop,
          },
        }
      )
      .then((res) => res.data),
};
