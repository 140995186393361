/* eslint-disable import/no-extraneous-dependencies */
import React, { lazy, Suspense } from "react";
import PropTypes from "prop-types";
import { Loader } from "@micmac/components";

const Before = lazy(() => import("./before"));
const After = lazy(() => import("./after"));

function Routes({ isAuth }) {
  if (isAuth === "true") {
    return (
      <Suspense fallback={<Loader />}>
        <After />
      </Suspense>
    );
  }
  return (
    <Suspense fallback={<Loader />}>
      <Before />
    </Suspense>
  );
}

Routes.propTypes = {
  isAuth: PropTypes.func.isRequired,
};
export default Routes;
