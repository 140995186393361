import Spinner from "../illustrations/spinner.svg";

function loader() {
  return (
    <div className="center">
      <img src={Spinner} alt="activity-loader" />
    </div>
  );
}

export default loader;
