/* eslint-disable import/prefer-default-export */
import styled from "styled-components";
import colors from "../theme/colors.json";

export const ProfileWrapper = styled.div`
  width: 100%;
  height: 20vh;
  border-radius: 0px 35px 35px 35px;
  background: ${colors.primary};
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-top: 20px;
  padding-bottom: 20px;
  line-height: 2.5;
  color: white;
  & > div {
    & > p {
      margin: 0px;
      font-size: 12px;
      font-weight: bold;
    }
  }
`;

export const Avatar = styled.img`
  width: 70px;
  height: 70px;
  border-radius: 50px;
`;

export const NavLists = styled.ul`
  display: flex;
  flex-direction: column;
  padding: 0px;
  padding-right: 25px;
  margin-top: 0px;
`;

export const NavList = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding-top: 20px;
  font-size: 12px;
  font-weight: 600;
  color: ${(props) => (props.active ? colors.primary : colors.tetiary)};
  
`;

export const NavListWrapper = styled.div`
  padding-left: 20px;
  display: flex;
  align-items: center;
  width: 100%;
`;

export const ArrowDown = styled.div``;

export const NavLink = styled.div`
  display: flex;
  cursor: pointer;
  width: 100%;
  align-items: center;
  & > span {
    margin-left: 1px;
  }
`;

export const NavIndicator = styled.div`
  height: 5vh;
  width: 4px;
  background: ${(props) => (props.active ? colors.primary : "")};
  border-radius: 6px;
  position: absolute;
`;

export const NavIcon = styled.div`
  padding-right: 15px;
  & > svg {
    fill: ${(props) => (props.active ? colors.primary : "")};
  }
`;

export const NavText = styled.span`
`;

export const SubNav = styled.ul`
  padding-left: 55px;
  position: relative;
  & > div {
    position: absolute;
    padding-top: 15px;
  }
  
`;

export const BackWrapper = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff933d;
`;
