import { SHORT_SIGNUP_STEP1, SHORT_SIGNUP_STEP2 } from "../types/auth";

const initialState = {
  email: null,
  pin: null,
  completeStep1: false,
  firstName: null,
  lastName: null,
  phoneNumber: null,
  cooperative: null,
  title: null,
  monthlyContribution: null,
  completeStep2: false,
  signUpType: null,
};

export default function shortSignup(state = initialState, action = {}) {
  switch (action.type) {
    case SHORT_SIGNUP_STEP1:
      return {
        ...state,
        email: action.data.email,
        pin: action.data.pin,
        completeStep1: true,
      };
    case SHORT_SIGNUP_STEP2:
      return {
        ...state,
        firstName: action.data.firstName,
        lastName: action.data.lastName,
        code: action.data.code,
        phoneNumber: action.data.phoneNumber,
        cooperative: action.data.cooperative,
        title: action.data.title,
        signUpType: action.data.signUpType,
        monthlyContribution: action.data.monthlyContribution,
        completeStep2: Math.random(),
      };
    default:
      return state;
  }
}
