import {
  FETCHING_USER,
  FETCHING_USER_ERROR,
  FETCHING_USER_SUCCESSFULL,
} from "../types/user";

const initialState = {
  loading: false,
  user: null,
  error: null,
};

export default function user(state = initialState, action = {}) {
  switch (action.type) {
    case FETCHING_USER:
      return {
        loading: true,
      };
    case FETCHING_USER_SUCCESSFULL:
      return {
        loading: false,
        user: action.result,
        error: null,
      };
    case FETCHING_USER_ERROR:
      return {
        loading: false,
        user: null,
        error: action.error,
      };

    default:
      return state;
  }
}
