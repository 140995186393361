import { BackWrapper } from "./navbar.style";
import BackArrow from "../illustrations/backArrow";

// eslint-disable-next-line react/prop-types
function Back({ onClick }) {
  return (
    <BackWrapper onClick={onClick}>
      <BackArrow />
    </BackWrapper>
  );
}

export default Back;
