/* eslint-disable no-unused-vars */
/* eslint-disable react/react-in-jsx-scope */
import Vote from "../components/illustrations/vote";
import Result from "../components/illustrations/result";
import Home from "../components/illustrations/home";
import User from "../components/illustrations/user";
import Nomination from "../components/illustrations/nomination";
import Campaign from "../components/illustrations/campaign";
import Calendar from "../components/illustrations/calendar";
import Support from "../components/illustrations/support";

const links = [
  {
    id: 1,
    title: "Home",
    link: "/",
    icon: <Home />,
  },
  {
    id: 2,
    title: "Dashboard",
    link: "/e-dashboard",
    icon: <Result />,
  },
  // {
  //   id: 3,
  //   title: "Nomination",
  //   icon: <Nomination />,
  // },
  // {
  //   id: 4,
  //   title: "Expression of Interest",
  //   icon: <User />,
  // },
  // {
  //   id: 5,
  //   title: "Candidate",
  //   link: "/candidate",
  //   icon: <User />,
  //   child: [
  //     {
  //       title: "Screened Candidates",
  //       link: "/screened-candidates",
  //     },
  //     {
  //       title: "Unscreened Candidates",
  //       link: "/unscreened-candidates",
  //     },
  //   ],
  // },
  // {
  //   id: 8,
  //   title: "Campaign",
  //   link: "/campaign",
  //   icon: <Campaign />,
  // },
  {
    id: 6,
    title: "Voting",
    link: "/e-voters",
    icon: <Vote />,
  },
  {
    id: 7,
    title: "Results",
    link: "/e-results",
    icon: <Result />,
  },
  {
    id: 9,
    title: "Timetable",
    link: "/view-timetable",
    icon: <Calendar />,
    child: [
      {
        title: "Set Time Table",
        link: "/set-timetable",
      },
      {
        title: "View Time Table",
        link: "/view-timetable",
      },
    ],
  },
  // {
  //   id: 10,
  //   title: "Help",
  //   icon: <Support />,
  //   link: "/help",
  // },
];

export default links;
