import { Button, colors, Modal } from "@micmac/components";
import React, { useEffect, useState } from "react";

const InstallPWA = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);
  const [show, setShow] = useState(false);

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener("beforeinstallprompt", handler);

    return () => window.removeEventListener("transitionend", handler);
  }, []);

  useEffect(() => {
    if (supportsPWA) {
      setShow(true);
    }
  }, [supportsPWA]);

  const onClick = (evt) => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }
    promptInstall.prompt();
  };
  if (!supportsPWA) {
    return null;
  }
  return (
    <div>
      {show && (
        <Modal
          show={show}
          spacing="auto"
          onClose={setShow}
          title="You can install this app"
          content={
            <div>
              <p>
                This application can be installed on your device with a single
                click for an easy access next time{" "}
              </p>
              <Button
                className="link-button"
                id="setup_button"
                aria-label="Install app"
                title="Install app"
                onClick={(e) => {
                  onClick(e);
                  setShow(false);
                }}
                style={{
                  lineHeight: "0",
                  padding: "18px",
                  width: "100px",
                }}
                theme={{
                  fg: colors.secondary,
                  bg: colors.primary,
                }}
              >
                Install application
              </Button>
            </div>
          }
        />
      )}
    </div>
  );
};

export default InstallPWA;
