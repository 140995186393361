/* eslint-disable react/react-in-jsx-scope */
/* eslint-disable import/prefer-default-export */
import Home from "../components/illustrations/home";
import Summary from "../components/illustrations/summary";
import Payroll from "../components/illustrations/payroll";
import Noty from "../components/illustrations/noty";

const links = [
  {
    id: 1,
    title: "Home",
    link: "/",
    icon: <Home />,
  },
  {
    id: 2,
    title: "Account Summary",
    link: "/account-summary",
    icon: <Summary />,
  },
  {
    id: 3,
    title: "Monthly Payroll Summary",
    link: "/payroll",
    icon: <Payroll />,
  },
  {
    id: 4,
    title: "E Notification",
    link: "/e-notification",
    icon: <Noty />,
  },
];

export default links;
