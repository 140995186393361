import erm from "../assests/erm.png";
import shop from "../assests/shop.png";
import rep from "../assests/reg.png";
import election from "../assests/election.png";
import album from "../assests/album.png";
import reg from "../assests/rep.png";
import audit from "../assests/audit.png";
import directory from "../assests/directory.png";
import insurance from "../assests/insurance.png";
import lottery from "../assests/loto.png";
import wallet from "../assests/wallet.png";
import chat from "../assests/chat.png";
import meeting from "../assests/meeting.png";
import news from "../assests/news.png";
import awards from "../assests/award.png";
import jobs from "../assests/job.png";
import ads from "../assests/ads.png";
import events from "../assests/event.png";

const data = [
  {
    name: "Coop ERM",
    icon: erm,
    localName: "erm",
    bg: "#faebdf",
    link: "/account-summary",
  },
  {
    name: "Coop Shop",
    localName: "shop",
    icon: shop,
    bg: "#ffd8c7",
    link: "/shop",
  },
  {
    name: "Coop Rep",
    localName: "rep",
    icon: rep,
    bg: "#fff0f0",
    link: "/welcome",
  },
  {
    name: "Coop Election",
    localName: "election",
    icon: election,
    bg: "#f3f6e5",
    link: "/e-dashboard",
  },
  {
    name: "Coop Album",
    icon: album,
    bg: "#e9eff4",
  },
  {
    name: "Coop Reg",
    icon: reg,
    bg: "#faebdf",
  },
  {
    name: "Coop Audit",
    icon: audit,
    bg: "#ffd8c7",
  },
  {
    name: "Coop Directory",
    icon: directory,
    bg: "#fff0f0",
  },
  {
    name: "Coop Lottery",
    icon: lottery,
    bg: "#fdf9ff",
  },
  {
    name: "Coop Insurance",
    icon: insurance,
    bg: "#f3f6e5",
  },
  {
    name: "Coop Wallet",
    icon: wallet,
    bg: "#faede4",
  },
  {
    name: "Coop Chat",
    icon: chat,
    bg: "#fbf5e4",
  },
  {
    name: "Coop Meeting",
    icon: meeting,
    bg: "#f4eaf8",
  },
  {
    name: "Coop News",
    icon: news,
    bg: "#e5e8e9",
  },
  {
    name: "Coop Awards",
    icon: awards,
    bg: "#ede6e4",
  },
  {
    name: "Coop Jobs",
    icon: jobs,
    bg: "#faf5e8",
  },
  {
    name: "Coop Ads",
    icon: ads,
    bg: "#f9f4e7",
  },
  {
    name: "Coop Events",
    icon: events,
    bg: "#fdefed",
  },
];

export default data;
