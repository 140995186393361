/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */

export default function Summary() {
  return (
    <svg width="18px" height="18px" viewBox="0 0 24.4 25.532">
      <path
        className="a"
        d="M0,22.9a2.3,2.3,0,0,0,2.352,2.227H16.16a2.309,2.309,0,0,0,2.3-1.854,2.266,2.266,0,0,0,1.958-2.2V11.046h1.122a.4.4,0,0,0,.221-.061l2.053-1.333a.4.4,0,0,0,0-.678L21.763,7.626a.417.417,0,0,0-.221-.077H20.42V5.338a.5.5,0,0,0-.18-.349L15.566.229a.406.406,0,0,0-.1-.1L15.459.12A.4.4,0,0,0,15.169,0H4.276a2.318,2.318,0,0,0-2.31,1.854A2.273,2.273,0,0,0,0,4.053ZM6.891,10.235V8.36H21.129v1.875ZM21.94,9.9V8.725l.9.588ZM15.657,1.46l3.361,3.455H16.2a.53.53,0,0,1-.542-.519ZM4.276.81h10.57V4.4A1.341,1.341,0,0,0,16.2,5.725h3.41V7.549H6.506a.443.443,0,0,0-.426.428v.052L4.874,8.005h0a.416.416,0,0,0-.405.418l.013,1.764a.405.405,0,0,0,.405.4h1.2v.06a.416.416,0,0,0,.426.4h.856l-.006.608a.892.892,0,0,0,.242.625.75.75,0,0,0,.525.237h4.922a.405.405,0,1,0,0-.811H8.169c0-.051,0-.025,0-.031l.006-.627H19.609V21.079A1.476,1.476,0,0,1,18.084,22.5H4.276a1.49,1.49,0,0,1-1.54-1.418V2.291c0-.021.013-.042.013-.063a.386.386,0,0,0,0-.052A1.484,1.484,0,0,1,4.276.811Zm1.8,8.968H5.286l-.017-.948.812.01ZM.811,4.053A1.481,1.481,0,0,1,1.925,2.686V21.079a2.3,2.3,0,0,0,2.351,2.229H17.621a1.487,1.487,0,0,1-1.461,1.013H2.352A1.49,1.49,0,0,1,.811,22.9Zm0,0"
        transform="translate(0 0)"
      />
      <path
        className="a"
        d="M88.866,268.811h6.665a.405.405,0,1,0,0-.811H88.866a.405.405,0,1,0,0,.811Zm0,0"
        transform="translate(-83.979 -254.421)"
      />
      <path
        className="a"
        d="M88.866,307.811h11.6a.405.405,0,0,0,0-.811h-11.6a.405.405,0,1,0,0,.811Zm0,0"
        transform="translate(-83.979 -291.445)"
      />
      <path
        className="a"
        d="M88.866,346.811h11.6a.405.405,0,0,0,0-.811h-11.6a.405.405,0,1,0,0,.811Zm0,0"
        transform="translate(-83.979 -328.469)"
      />
      <path
        className="a"
        d="M88.866,385.811h11.6a.405.405,0,0,0,0-.811h-11.6a.405.405,0,1,0,0,.811Zm0,0"
        transform="translate(-83.979 -365.493)"
      />
    </svg>
  );
}
