/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */

export default function Scroll({ className, style }) {
  return (
    <svg
      className={className}
      width="18px"
      height="18px"
      viewBox="0 0 511.999 511.999"
    >
      <path d="M454.759,0v88.429h18.889c8.284,0,15-6.716,15-15V45.23C488.648,23.838,474.32,5.74,454.759,0z" />
      <path
        d="M134.768,0.001L134.768,0.001C108.776,0,87.629,21.146,87.629,47.139v374.524h257.23c8.284,0,15,6.716,15,15v28.199
			c0,9.45,7.688,17.139,17.139,17.139h0.235h47.139l0.388-482H134.768z M344.859,354.088H159.724c-8.284,0-15-6.716-15-15
			s6.716-15,15-15h185.135c8.284,0,15,6.716,15,15S353.143,354.088,344.859,354.088z M344.859,278.695H159.724
			c-8.284,0-15-6.716-15-15s6.716-15,15-15h185.135c8.284,0,15,6.716,15,15S353.143,278.695,344.859,278.695z M344.859,203.301
			H159.724c-8.284,0-15-6.716-15-15s6.716-15,15-15h185.135c8.284,0,15,6.716,15,15S353.143,203.301,344.859,203.301z
			 M344.859,127.908H159.724c-8.284,0-15-6.716-15-15s6.716-15,15-15h185.135c8.284,0,15,6.716,15,15
			S353.143,127.908,344.859,127.908z"
      />
      <path
        d="M329.859,464.861v-13.199H36.55c-7.29,0-13.199,5.909-13.199,13.199c0,25.592,20.505,46.472,45.946,47.108
			c0.2,0.008,0.397,0.03,0.599,0.03H96.44h280.557C351.005,512,329.859,490.854,329.859,464.861z"
      />
    </svg>
  );
}
