import { combineReducers } from "redux";
import cooperatives from "./cooperatives";
import shortSignup from "./shortSignUp";
import user from "./user";

export default combineReducers({
  cooperatives,
  user,
  shortSignup,
});
