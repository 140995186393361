/* eslint-disable import/prefer-default-export */
import styled from "styled-components";
import colors from "../styles/colors.json";

export const NavbarContainer = styled.div`
  @media (max-width: 992px) {
    width: 100%;
  }
`;

export const NavbarWrapper = styled.div`
  height: 100vh;
  position: fixed;
  top: 0px;
  background: ${(props) => (props.color && colors.primary) || "white"};
  box-shadow: 0px 4px 12px #0000001a;
  border: 1px solid #1e1c1b0d;
  border-radius: ${(props) =>
    (props.borderType && "0px 35px 35px 0px") || "0px 35px 0px 0px"};
    width: ${(props) => (props.width && "319px") || "10%"};
  }
  @media (max-width: 992px) {
    width: ${(props) => (props.width && "65%") || "40%"};
  }
  z-index: 999;
`;

export const DefaultNavLists = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  padding-top: 50px;
  padding-bottom: 50px;
  & > img {
    width: 50px;
  }
  height: 80vh;
`;

export const DefaultNavBottom = styled.div`
  & > div {
    padding-top: 40px;
    color: white;
    & > span {
      padding-left: 5px;
      font-size: 13px;
      font-weight: bold;
    }
  }
`;

export const NavLink = styled.div`
  display: flex;
  cursor: pointer;
  width: 100%;
  align-items: center;
  & > span {
    margin-left: 1px;
  }
`;

export const BackWrapper = styled.div`
  width: 25px;
  height: 25px;
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ff933d;
`;
