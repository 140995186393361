/* eslint-disable react/prop-types */
import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import { useMediaQuery } from "react-responsive";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { SideNav } from "@micmac/components";
import { NavbarContainer } from "./navbar.style";
import Menu from "../illustrations/menu";
import Nav from "./nav";
import DefaultNav from "./defaultNavItem";

function Navbar({ history, user, links }) {
  const isMobile = useMediaQuery({ query: "(max-width:992px)" });
  const [show, setShow] = useState(false);

  let navType = <DefaultNav user={user} />;
  let borderType = true;
  let width = false;
  let color = true;
  if (history.location.pathname !== "/") {
    navType = (
      <SideNav setShow={setShow} history={history} user={user} links={links} />
    );
    borderType = false;
    width = true;
    color = false;
  }
  if (isMobile) {
    return (
      <OutsideClickHandler
        onOutsideClick={() => {
          setShow(false);
        }}
      >
        <NavbarContainer width={width}>
          <Nav
            show={show}
            navItem={navType}
            borderType={borderType}
            color={color}
            width={width}
          />
          <Menu onClick={() => setShow(true)} />
        </NavbarContainer>
      </OutsideClickHandler>
    );
  }
  return (
    <NavbarContainer width={width}>
      <Nav
        show={true}
        width={width}
        navItem={navType}
        borderType={borderType}
        color={color}
      />
    </NavbarContainer>
  );
}

function mapStateToProps(state) {
  return {
    user: state.user.user,
  };
}

export default withRouter(connect(mapStateToProps, null)(Navbar));
