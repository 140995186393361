/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */

export default function Cooperative({ className, style }) {
  return (
    <svg className={className} width="22px" height="22px" viewBox="0 0 64 64">
      <path d="m52 37h2v14h-2z" />
      <path d="m45.904 38.995-9.999-.963-6.228 4.629c-.399.268-.628.648-.67 1.075-.042.428.107.846.411 1.149.478.479 1.236.555 1.8.179l3.798-2.863 1.203 1.598-1.618 1.219 9.627 7.877 5.772-1.65v-13.025l-3.804.761c-.096.018-.194.023-.292.014z" />
      <path d="m32.375 46.695c-.604.403-1.287.6-1.959.6-.887 0-1.757-.341-2.412-.995-.729-.728-1.088-1.734-.987-2.76.102-1.026.652-1.942 1.51-2.514l2.699-2.007-1.487-.991-10.649.968c-.084.008-.17.004-.255-.01l-4.835-.806v11.82h4c.127 0 .253.024.372.071l14.688 5.876 8.805-2.401-8.909-7.289z" />
      <path d="m10 37h2v14h-2z" />
      <path d="m3 35h6v18h-6z" />
      <path d="m55 35h6v18h-6z" />
      <path d="m20 21c0 6.627 5.373 12 12 12s12-5.373 12-12-5.373-12-12-12-12 5.373-12 12zm17-3.5v.5h-2v-.5c0-1.379-1.122-2.5-2.5-2.5h-1c-1.378 0-2.5 1.121-2.5 2.5s1.122 2.5 2.5 2.5h1c2.481 0 4.5 2.019 4.5 4.5 0 2.31-1.756 4.197-4 4.449v3.051h-2v-3.051c-2.244-.252-4-2.139-4-4.449v-.5h2v.5c0 1.379 1.122 2.5 2.5 2.5h1c1.378 0 2.5-1.121 2.5-2.5s-1.122-2.5-2.5-2.5h-1c-2.481 0-4.5-2.019-4.5-4.5 0-2.31 1.756-4.197 4-4.449v-3.051h2v3.051c2.244.252 4 2.139 4 4.449z" />
    </svg>
  );
}
