/* eslint-disable import/prefer-default-export */
import styled from "styled-components";
import { colors } from "@micmac/components";

export const WidgetContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  padding-bottom: 5px;
`;
export const Toggle = styled.button`
  background: white;
  border-radius: 50px;
  width: 50px;
  height: 50px;
  box-shadow: 3px 5px 10px #ccc;
  border: none;
  cursor: pointer;
  position: absolute;
  left: 0;
  margin-left: 10px;
  margin-top: -50px;
`;
export const WidgetWrapper = styled.div`
  box-shadow: 0px 22px 35px #0000001a;
  background: white;
  border-radius: 10px;
  width: 800px;
  height: 8vh;
  padding-top: 5px;
  @media (max-width: 992px) {
    width: 100%;
    border-radius: 0px;
    border-top: 1px solid ${colors.lightGray};
    box-shadow: none;
  }
`;

export const Applist = styled.div`
  margin-left: 5px;
  & > a {
    display: flex;
    flex-direction: column;
    align-items: center;
    & > div {
      width: 30px;
      height: 30px;
      @media (max-width: 992px) {
        width: 25px;
        height: 25px;
      }
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      & > div {
        width: 25px;
        & > img {
          width: 100%;
          height: 100%;
          display: block;
        }
      }
    }
  }
`;

export const TradeMarkWrapper = styled.div`
  width: 99.6%;
  padding: 2px;
  background: ${colors.primary};
  text-align: center;
  color: white;
  font-size: 13px;
  font-weight: bold;
  @media (max-width: 992px) {
    font-size: 11px;
    width: 98.9%;
  }
`;

export const AppName = styled.p`
  font-weight: bold;
  font-size: 10px;
  margin: 0px;
  padding-top: 5px;
  color: ${(props) => props.active && colors.primary};
`;
