import styled from "styled-components";

export const PaginationWrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  & > div {
    width: 40%;
    @media (max-width: 992px) {
      width: 100%;
    }
  }
`;
