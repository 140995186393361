/* eslint-disable import/no-extraneous-dependencies */
import React from "react";
import PropTypes from "prop-types";
import { LayoutWrapper } from "./layout.style";

function layout({ children }) {
  return <LayoutWrapper>{children}</LayoutWrapper>;
}

layout.propTypes = {
  children: PropTypes.bool.isRequired,
};

export default layout;
