import {
  FETCHING_COOPERATIVES,
  SUCCESSFULLY_FETCHED_COOPERATIVES,
  FAILED_FECTHING_COOPERATIVES,
} from "../types/cooperatives";

const initialState = {
  loading: false,
  cooperatives: null,
  error: null,
};

export default function cooperatives(state = initialState, action = {}) {
  switch (action.type) {
    case FETCHING_COOPERATIVES:
      return {
        loading: true,
      };
    case SUCCESSFULLY_FETCHED_COOPERATIVES:
      return {
        ...state,
        loading: false,
        cooperatives: action.results,
      };
    case FAILED_FECTHING_COOPERATIVES:
      return {
        ...state,
        loading: false,
        cooperatives: null,
        error: action.error,
      };
    default:
      return state;
  }
}
