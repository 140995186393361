/* eslint-disable react/prop-types */
import { useState, useEffect } from "react";
import OutsideClickHandler from "react-outside-click-handler";
import queryString from "query-string";
import { useHistory } from "react-router-dom";
import {
  SelectWrapper,
  SelectedOptionWrapper,
  SelectLists,
  SelectList,
} from "./select.style";

function Select({
  selected,
  options,
  setSelected,
  index,
  title,
  onClick,
  disabled,
}) {
  const history = useHistory();
  const { search, pathname } = history.location;
  const parsed = queryString.parse(search);
  const [open, setOpen] = useState(false);
  useEffect(() => {}, [open]);
  return (
    <SelectWrapper className={disabled && "disabled"}>
      <SelectedOptionWrapper>
        {selected ? (
          <p>{selected[index] || selected}</p>
        ) : (
          <span>Click {title}</span>
        )}
      </SelectedOptionWrapper>
      <OutsideClickHandler onOutsideClick={() => setOpen(false)}>
        <SelectLists hide={open}>
          {options &&
            options.map((item) => {
              let active = false;
              if (selected && selected[index] === item[index]) {
                active = true;
              }
              return (
                <SelectList
                  onClick={() => {
                    setSelected(item);
                    parsed[Object.keys(item)[1]] = item[index];
                    const stringified = queryString.stringify(parsed);
                    history.push({
                      pathname,
                      search: stringified,
                    });
                    setOpen(true);
                    if (onClick) {
                      onClick();
                    }
                  }}
                  key={item[index]}
                  active={active}
                >
                  {item[index]}
                </SelectList>
              );
            })}
        </SelectLists>
      </OutsideClickHandler>
    </SelectWrapper>
  );
}

export default Select;
