/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */

export default function Scroll() {
  return (
    <svg width="18px" height="18px" viewBox="0 0 25 25">
      <path
        d="M24.156,11.306a2.765,2.765,0,0,0-2.039-.755,2.722,2.722,0,0,0-1.486.542l-5.755,4.313-.007,0a3.916,3.916,0,0,1-4.738,0l-.007,0L4.367,11.091a2.719,2.719,0,0,0-1.484-.54,2.766,2.766,0,0,0-2.039.755A2.7,2.7,0,0,0,0,13.284v5.856A5.866,5.866,0,0,0,5.859,25H19.141A5.866,5.866,0,0,0,25,19.141V13.284A2.7,2.7,0,0,0,24.156,11.306Zm-1.109,7.835a3.911,3.911,0,0,1-3.906,3.906H5.859a3.911,3.911,0,0,1-3.906-3.906V13.284a.753.753,0,0,1,.236-.562.818.818,0,0,1,.554-.223h.038a.76.76,0,0,1,.418.155l5.752,4.31a5.874,5.874,0,0,0,7.1,0L21.8,12.658a.763.763,0,0,1,.42-.157.815.815,0,0,1,.592.222.753.753,0,0,1,.236.562v5.856ZM2.441,7.666V5.859A5.866,5.866,0,0,1,8.3,0h8.5a5.866,5.866,0,0,1,5.859,5.859V7.666a.977.977,0,0,1-1.953,0V5.859A3.911,3.911,0,0,0,16.8,1.953H8.3A3.911,3.911,0,0,0,4.395,5.859V7.666a.977.977,0,0,1-1.953,0ZM11.6,12.842v-.127a7.613,7.613,0,0,1-1.288-.381.977.977,0,1,1,.721-1.815,5.183,5.183,0,0,0,1.666.37.489.489,0,0,0,.488-.488c0-.274-.29-.488-.659-.488A2.571,2.571,0,0,1,9.741,7.471,2.445,2.445,0,0,1,11.6,5.1V5.029a.977.977,0,0,1,1.953,0v.18a7.836,7.836,0,0,1,.842.277.977.977,0,1,1-.708,1.82,3.849,3.849,0,0,0-1.5-.324.489.489,0,0,0-.488.488c0,.278.357.488.83.488A2.5,2.5,0,0,1,15.137,10.4a2.445,2.445,0,0,1-1.587,2.287v.155a.977.977,0,1,1-1.953,0Z"
        transform="translate(0)"
      />
    </svg>
  );
}
