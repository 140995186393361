/* eslint-disable import/no-extraneous-dependencies */
import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { withRouter } from "react-router-dom";
import { NavbarWrapper } from "./navbar.style";

function Nav({ show, navItem, borderType, color, width }) {
  useEffect(() => {}, [width]);
  if (!show) {
    return <div></div>;
  }
  return (
    <NavbarWrapper width={width} borderType={borderType} color={color}>
      {navItem}
    </NavbarWrapper>
  );
}

Nav.propTypes = {
  navItem: PropTypes.element.isRequired,
  show: PropTypes.bool.isRequired,
  borderType: PropTypes.bool.isRequired,
  color: PropTypes.bool.isRequired,
  width: PropTypes.bool.isRequired,
};
export default withRouter(Nav);
