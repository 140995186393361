/* eslint-disable no-unused-vars */
/* eslint-disable react/prop-types */
/* eslint-disable react/react-in-jsx-scope */

export default function Support({ className, style }) {
  return (
    <svg className={className} width="18px" height="18px" viewBox="0 0 512 512">
      <path d="m356 340h-45v-14.029c12.21-6.678 23.083-15.505 32.107-25.971h32.893c30.327 0 55-24.673 55-55v-50c0-19.553-12.543-36.214-30-42.404v-7.596c0-79.953-65.047-145-145-145s-145 65.047-145 145v7.58c-17.459 6.192-30 22.865-30 42.42 0 24.813 20.187 45 45 45h15.978c4.856 37.115 27.477 68.719 59.022 85.971v14.029h-45c-68.925 0-125 56.075-125 125v32c0 8.284 6.716 15 15 15h420c8.284 0 15-6.716 15-15v-32c0-68.925-56.075-125-125-125zm45-95c0 13.785-11.215 25-25 25h-14.174c4.023-9.425 6.821-19.491 8.196-30h30.978zm0-50v15h-30v-30h15c8.271 0 15 6.729 15 15zm-260 15h-15c-8.271 0-15-6.729-15-15s6.729-15 15-15h15zm0-60v-5c0-63.411 51.589-115 115-115s115 51.589 115 115v5h-9.738l-45.892-36.713c-5.969-4.775-14.572-4.298-19.977 1.106-22.96 22.961-53.489 35.607-85.961 35.607zm30 75v-45h38.432c35.954 0 69.999-12.436 97.22-35.27l34.348 27.479v52.791c0 16.518-4.752 31.939-12.938 45h-62.062c-8.284 0-15 6.716-15 15s6.716 15 15 15h29.948c-11.917 6.373-25.515 10-39.948 10-46.869 0-85-38.131-85-85zm110 112.255v17.745c0 13.785-11.215 25-25 25s-25-11.215-25-25v-17.745c8.052 1.792 16.416 2.745 25 2.745s16.948-.953 25-2.745zm170 144.745h-390v-17c0-52.383 42.617-95 95-95h47.096c6.547 23.057 27.777 40 52.904 40s46.356-16.943 52.904-40h47.096c52.383 0 95 42.617 95 95z" />
    </svg>
  );
}
