import { createGlobalStyle } from "styled-components";
import colors from "./components/styles/colors.json";

const GlobalStyles = createGlobalStyle`
body {
  margin: 0;
  font-family: 'Lato', sans-serif;
}
a{
  color:${colors.gray};
  
}
.no-js{
  text-align: center;
}
img{
  max-width: 100%;
  max-height: 100%;
}
.carousel{
  margin-top:-70px !important;
  
}

.carousel .carousel-status{
  display:none;
}
.previous, .next {
  width: 22px;
  cursor: pointer;
  height: 22px;
  text-align: center;
  border: none !important;
  border-radius: 50px;
}
.calimg{
  top: 150px;
  left: 1264px;
  width: 300px !important;
  height: 300px;
  align-items:center;
}
.homespan{
  font-weight:200;
height: 61px;
color:#fff;
top:200px;
font-size:44px;
text-align:center;
position:absolute;
}
h1{
  font-weight: bold;
  font-size: bold;
  font-size: 24px;
  margin:0px;
  padding: 0px;
}

.text-summary{
  font-size: 14px;
  color: ${colors.gray};
}

form{
  padding-top: 20px;
}

a{
  color: black;
  text-decoration: none;
}

input{
  border-radius: 4px;
  border: 1px solid ${colors.primary};
  box-shadow: none;
  box-sizing: border-box;
  color: #444;
  margin-top:5px;
  display: block;
  font-size: .875rem;
  line-height: 2.125rem;
  padding: .4rem .9375rem;
  width: 100%;
  transition: background-color .15s,box-shadow .15s,border-color .15s,color .15s;
  &:focus{
    outline: none;
  };
  &::placeholder{
    color: ${colors.lightGray}
  }
}
textarea{
  border-radius: 4px;
  border: 1px solid ${colors.primary};
  box-shadow: none;
  box-sizing: border-box;
  color: #444;
  margin-top:5px;
  display: block;
  min-height: 20vh;
  font-size: .875rem;
  line-height: 1.125rem;
  padding: .4rem .9375rem;
  width: 100%;
  transition: background-color .15s,box-shadow .15s,border-color .15s,color .15s;
  &:focus{
    outline: none;
  };
  &::placeholder{
    color: ${colors.lightGray}
  }
}

.input-error {
  background-color: #ff8686;
}
.input-error-message{
  color: rgb(255, 53, 53);
  font-size: 13px;
}

.input-group{
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > div{
    width: 49%;
  }
}


label{
  font-size: 12px;
  font-weight: bold;
 
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

select{
  width: 100%;
  font-size: .875rem;
  line-height: 2.125rem;
  height: 50px;
  padding: .4rem .9375rem;
  border-radius: 5px;
  margin-top:5px;
  border: 1px solid ${colors.primary};
  background: transparent;
  & > option {
    width: 250px;
    font-size: 16px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
}
.input-wrapper{
  text-align:left;
  padding-top: 15px;
  
  
}
.llegend{
  background:transparent !important;
  color:#fff;
  font-size:30px !important;
  font-weight:300 ! important;
  opacity:0.9 !important;
margin:0px !important;
padding:0px !important;

  
 
}
.boldtext{
  font-weight:900 !important;
  margin:20px !important;
  line-height:1.4;

}
.select-drop{
  border-radius: 4px;
  box-shadow:none !important;
  border:1px solid ${colors.primary};
  margin-top:5px;
}
.css-yk16xz-control{
  border-color:none !important;
  border-style:none !important; 
  min-height: 0px !important;
  font-size: .875rem !important;
  height:33px;
  &:focus{
  outline:none !important;
  background:red;
}
}
.css-1wa3eu0-placeholder {
  color: ${colors.lightGray} !important;
  font-size: .875rem !important;
}

.code-input{
  display: flex !important;
  margin-left:-4px;
  margin-top: 5px;
  & > input{
  font-family: monospace;
  -moz-appearance: textfield;
  border-radius: 4px;
  margin: 4px;
  padding-left: 8px;
  height: 34px;
  font-size: 24px;
  box-sizing: border-box;
  color: black;
  text-align:center;
  background-color: white;
  }
}

.code-input-error{
  display: flex !important;
  margin-left:-4px;
  margin-top: 5px;
  & > input{
  font-family: monospace;
  moz-ppearance: textfield;
  border-radius: 4px;
  margin: 4px;
  padding-left: 8px;
  height: 34px;
  font-size: 24px;
  box-sizing: border-box;
  color: rgb(185, 74, 72);
  text-align:center;
  background-color: rgb(242, 222, 222);
  border: 1px solid red;
  box-shadow: rgb(0 0 0 / 10%) 0px 0px 10px;
}
}

.phone-input{
  width: 100% !important;
  height: 50px !important;
  border: 1px solid ${colors.primary} !important;
}
.phone-btn{
  background: transparent !important;
  border: 1px solid ${colors.primary} !important;
}
.pages{
  width: 100%;
  @media (min-width: 992px) {
    margin-left: 319px;
  }
}
.small-text{
  color:#fff;
  bottom:70 !important;
  font-size:14px;
  text-align:center
 
}
.carousel .control-dots {
  margin: -5px 0 !important;
}
.alert {
  color: #a94442;
  background-color: #f2dede;
  border-color: #ebccd1;
  padding: 15px;
    margin-bottom: 20px;
    border: 1px solid transparent;
    border-radius: 4px;
}
.modalavatar{
  margin-top:8px;
  width: 50px;
  height: 50px;
  border-radius: 50px;
}
.modalavatar-sm{
  width: 30px;
  height: 30px;
  border-radius: 50px;
}
.carousel-root{
  overflow-x:hidden;
}
.closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}

.closebtn:hover {
  color: black;
}
.pages-wrapper{
  margin:0px auto;
  margin-top: 1rem;
  max-width: 64.375rem;
  box-shadow: 0px 3px 12px #00000024;
  min-height: 90vh;
  border-radius: 10px;
  padding: 2.313rem;
  @media (max-width: 992px){
    padding:0px;
    box-shadow: none;
    margin-left: 10px;
    margin-right: 10px;
    padding-bottom: 20px;
  }
}
.filter-wrapper{
  @media (min-width:992px){
    box-shadow: 0px 3px 12px #00000024;
  }
}

.inner-component{
    @media (max-width: 992px){
    box-shadow: 0px 3px 12px #00000024;
    padding: 10px;
    margin-left: 0px;
    margin-right: 0px;
  }
}
  .inner-wrapper{
    margin-top: 10px;
    
    box-shadow: 0px 3px 12px #00000024;
    min-height: 20vh;
    border-radius: 10px;
    @media (max-width: 992px){
    
     
     
    }
  }
  .notification-wrapper{
    margin-top: 50px;
    padding: 15px;
    border-radius: 10px; 
    border: 1px solid #1E1C1B0A ;
    list-style-type:none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    @media (max-width: 992px){
      padding: 10px;
    }
  }
  
  .notification-log{
    width: 30px;
  }
  .notification-details{
    width: 85%;
    @media (max-width: 992px){
      margin-left: 5px;
    }
  }
  .notification-wrapper:hover{
    box-shadow: 0px 3px 12px #00000024;
  }
  .notification-title{
    color: #1E1C1B;
    font-size: 14px;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0px;
    padding: 0px;
    @media (max-width: 992px){
      font-size: 12px;
    }
   
  }.notif-text{
    font-size:14px;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    margin: 0px;
    padding: 0px;
    padding-top: 4px;
    @media (max-width: 992px){
      font-size: 13px;
    }
  }
    .payroll-small-header-wrapper{
      height:6vh;
      pading:40px;
      background:${colors.primary};
      color: ${colors.secondary};
      font-size: 14px;
      font-weight:bold;
      border-radius: 5px;
    }
    .payroll-small-header-text{
      margin-left:60px;
    }
.payroll-select-drop{
  border-radius: 4px;
  box-shadow: none !important;
  border: 1px solid #E57C29;
  margin-top: 5px;
}
.header-wrapper{
  background:${colors.primary};
  color: ${colors.secondary};
  padding: 0.688rem 0 0.688rem 1.813rem;
  font-size: 1.3rem;
  display: flex;
  align-items: center;
  font-weight:bold;
  border-radius: 10px;
  & > div:nth-child(2){
    margin-left: 10px;
  }
  @media(max-width:992px){
    padding: 8px;
    font-size: 1rem;
  }
}

.small-header-wrapper{
  padding: 10px;
  background:${colors.primary};
  color: ${colors.secondary};
  font-size: 14px;
  font-weight:bold;
  border-radius: 5px;
}
.inner-header-wrapper{
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.bold-link{
  font-weight:bold;
  font-size:12px;
  text-decoration:underline;
}

.react-datepicker-wrapper{
width: 100%;
}

.check{
  padding-top: 10px;
  & > div{
    display: flex;
    align-items: center;
    & > label {
      margin-left: 5px;
      margin-top: 2px;
    }
    & > span {
      font-size: 13px;
    }
  }
}

label input {
  visibility: hidden;/* <-- Hide the default checkbox. The rest is to hide and allow tabbing, which display:none prevents */
  display: block;
  height: 0;
  width: 0;
  cursor: pointer;
  position: absolute;
  overflow: hidden;
}
label span {/* <-- Style the artificial checkbox */
  height: 15px;
  width: 15px;
  border: 1.6px solid ${colors.primary};
  display: inline-block;
  border-radius: 50px;
  cursor: pointer;
}
[type=radio]:checked + span {/* <-- Style its checked state */
  background: ${colors.primary};
}
[type=checkbox]:checked + span {/* <-- Style its checked state */
  background: ${colors.primary};
}

.table-wrapper{
    overflow:hidden;
    overflow-y: scroll;
   height:500px;
}
table {
  width: 100%;
  box-shadow: 0px 6px 12px #1E1C1B12;
  border-collapse: collapse;
  border-radius: 10px;
  overflow: hidden;
}
.fixed-table{
  table-layout: auto;
  @media (max-width: 992px){
    table-layout: fixed;
  }
}
td, th {
  text-align: left;
  padding:15px 0px 15px 15px;
  font-size: 13px;
  font-weight: bold;
  @media (max-width:992px){
  font-size: 12px;
  white-space: nowrap; 
  overflow: hidden;
  text-overflow: ellipsis; 
  }
}
th {
    top: 0;
    z-index: 2;
    position: sticky;
 }
.table-head{
  background: ${colors.primary};
  color: white;
 
  @media(max-width: 992px){
    & > th{
      padding: 15px 0px 15px 15px;
    }
  }
}
.table-bottom{
  color: ${colors.primary};
  background: white;
}
.coop-file{
  width: 10px;
  text-align: center;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
tr{
  cursor: pointer;
}
tr:nth-child(even) {
  background-color: #FEEBCE;
}

.hidden-td{
  visibility: hidden;
}
.center{
  padding: 50px;
  text-align: center;
}

.optional-padding{
  margin-left: 150px;
  margin-right: 150px;
  @media (max-width: 670px){
    margin-left: 0px;
    margin-right: 0px;
  }
}
details[disabled] summary,
details.disabled summary {
  pointer-events: none; /* prevents click events */
  user-select: none; /* prevents text selection */
}
.transaction-buttom{
  background-color: #f99c2e !important;
  color: white !important;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 15px;
}
.pdf-preview{
  min-height: 30vh !important;
}
.react-pdf__Page{
  display: flex;
  justify-content: center;
}
.react-pdf__Page__canvas{
  width: 60% !important;
  height: 100% !important;
}
.my-masonry-grid {
  display: -webkit-box; /* Not needed if autoprefixing */
  display: -ms-flexbox; /* Not needed if autoprefixing */
  display: flex;
  justify-content: center;
  margin-left: -30px; /* gutter size offset */
  width: auto;
}
.my-masonry-grid_column {
  padding-left: 30px; /* gutter size */
  background-clip: padding-box;
}

/* Style your items */
.my-masonry-grid_column > div {
  width: 100%;
  margin-bottom: 30px;
}
.apps-slider{
    margin-left: -10px;
}
.react-multiple-carousel__arrow:hover{
  background: ${colors.gray}
}
.react-multiple-carousel__arrow{
  background: white;
  box-shadow: 20px 0px 80px 20px #0000001a;
  min-width: 25px !important;
  min-height: 25px !important;
}
.react-multiple-carousel__arrow::before{
  color: ${colors.primary};
  font-size: 14px;
}

.additional-wrapper{
  text-align: center;
}

@media(min-width:992px){
  .additional-wrapper{
    text-align: left
  }
  .additional-text{
    display: none
  }
}
`;

export default GlobalStyles;
